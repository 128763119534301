<script>
export default {
  locales: {
    pt: {
      'CODE': 'CÓDIGO',
      'INVESTMENT': 'INVESTIMENTO',
      'PROFIT': 'RENDIMENTO',
      'PERCENT': 'PORCENTAGEM',
      'DATE': 'DATA',
      'ACTIVE': 'ATIVO',
      'RENEW': 'RENOVAR',
    },
    es: {
      'CODE': 'CÓDIGO',
      'INVESTMENT': 'INVERSIÓN',
      'PROFIT': 'RENDIMIENTO',
      'PERCENT': 'PORCENTAJE',
      'DATE': 'DATA',
      'ACTIVE': 'ATIVO',
      'RENEW': 'RENOVAR',
    }
  },
  props: {
    code: {
      type: String,
      default: ""
    },
    status: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    earnings: {
      type: String,
      default: ""
    },
    percent: {
      type: String,
      default: "0"
    },
    profile: {
      type: String,
      default: "200%"
    },
    date: {
      type: String,
      default: ""
    }
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body py-3">
      <div class="row">
        <div class="col-6 col-lg-2">
          <small class="text-muted mb-0">{{ t('CODE') }}</small>
          <p class="mb-0 notranslate">{{ code }}</p>
        </div>
        <div class="col-6 col-lg-2">
          <small class="text-muted mb-0">{{ t('STATUS') }}</small>
          <p class="mb-0 notranslate">
            <span v-if="status === 'active'" class="badge badge-pill badge-soft-success font-size-12">{{ t('ACTIVE') }}</span>
            <span v-else-if="status === 'renew'" class="badge badge-pill badge-soft-danger font-size-12">{{ t('RENEW') }}</span>
          </p>
        </div>
        <div class="col-6 col-lg-3">
          <small class="text-muted mb-0">{{ t('INVESTMENT') }}</small>
          <p class="mb-0 notranslate">{{ value }}</p>
        </div>
        <div class="col-6 col-lg-3">
          <small class="text-muted mb-0">{{ t('PROFIT') }}</small>
          <p class="mb-0 notranslate">{{ earnings }}</p>
        </div>
        <div class="col-6 col-lg-2">
          <small class="text-muted mb-0">{{ t('DATE') }}</small>
          <p class="mb-0 notranslate">{{ date }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Layout from "../../layouts/main";
import { VclList } from "vue-content-loading";
import { api } from "@/state/services";
import Earning from "@/components/widgets/earning";

export default {
  locales: {
    pt: {
      'My Dividends': 'Meus Investimentos',

      'Code': 'Código',
      'Date': 'Data',
      'Value': 'Valor',
      'Earnings': 'Ganhos',
      'Percent': 'Porcentagem',
      'Profile': 'Perfil',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'My Dividends': 'Meus Investimentos',

      'Code': 'Código',
      'Date': 'Data',
      'Value': 'Valor',
      'Earnings': 'Ganhos',
      'Percent': 'Porcentagem',
      'Profile': 'Perfil',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: { Layout, Earning, VclList },
  data() {
    return {
      investments: null,
      loading: true,
      errored: false,
      empty: false
    };
  },
  methods: {
    getInvestment() {
      api
        .get('investments')
        .then(response => {
          if (response.data.status=='success') {
            this.investments = response.data.list
            this.loading = false;
          } else {
            this.investments = null
            this.empty = true;
            this.loading = false;
          }
        })
        .catch(error => {
          this.errored = error;
        })
    },
  },
  mounted() {
    this.getInvestment()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('My Dividends') }}</h4>
        </div>
      </div>
    </div>
    <div class="card" v-if="errored">
      <div class="card-body">
        <div >{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
      </div>
    </div>
    <div class="card" v-else-if="empty">
      <div class="card-body">
        <div >{{ t('No records found.') }}</div>
      </div>
    </div>
    <vcl-list v-else-if="loading" class="col-lg-6"></vcl-list>
    <div v-else class="row">
      <div class="col-xl-12">
        <div v-for="(investment,key) of investments" :key="key">
          <Earning :code="investment.code" :status="investment.status" :value="investment.value" :earnings="investment.earnings" :percent="investment.percent" :profile="investment.profile" :date="investment.date" />
        </div>
      </div>
    </div>
  </Layout>
</template>

